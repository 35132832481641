import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Docente {
  name: string;
  category: string;
  department: string;
}

interface Administrativos {
    name: string;
    role: string;
    department: string;
}

interface PagosEspeciales {
    name: string;
    role: string;
    observations: string;
}

interface InformesFinancieros {
    year: string;
    month: string;
    source: string;
    amount: string;
    expenseType: string;
}

interface MatriculaGratis {
  year: string;
  month: string;
  amount: string;
}

interface Inventarios {
  item: string;
  quantity: string;
  condition: string;
  usage: string;
  fundSource: string;
  acquisitionDate: string;
}

interface AlimentacionEscolar {
  year: string;
  month: string;
  cantidadNinos: string;
  cantidadDotaciones: string;
  descripcion: string;
}

interface Becas {
  cantidadAlumnos: string;
  tipoBeneficio: string;
  origenFinanciamiento: string;
  periodoEntrega: string;
}

interface Escuela {
  CodigoSACE: string;
  CentroEducativo: string;
  ALDEA: string;
  MUNICIPIO: string;
  DEPARTAMENTO: string;
  Photo: string;
  Docentes: Docente[];
  Administrativos: Administrativos[];
    PagosEspeciales: PagosEspeciales[];
    InformesFinancieros: InformesFinancieros[];
    MatriculaGratis: MatriculaGratis[];
    Inventarios: Inventarios[];
    AlimentacionEscolar: AlimentacionEscolar[];
    Becas: Becas[];
}

interface EscuelasState {
  escuelas: Escuela[];
}

const initialState: EscuelasState = {
  escuelas: [],
};

const escuelasSlice = createSlice({
  name: 'escuelas',
  initialState,
  reducers: {
    setEscuelas(state, action: PayloadAction<Escuela[]>) {
      state.escuelas = action.payload;
    },
  },
});

export const { setEscuelas } = escuelasSlice.actions;

export default escuelasSlice.reducer;
