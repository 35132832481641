import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EscuelasList from './components/EscuelasList';
import SchoolDetail from './components/SchoolDetail';
import { Layout } from 'antd';
import AppHeader from './components/Header';
import AppFooter from './components/Footer';

function App() {
  return (
    <Router>
      <Layout>
        <AppHeader />
        <Layout.Content
          style={{
            padding: '0 20px',
            marginTop: '10px',
            maxWidth: '1200px', // Limitar el ancho máximo para pantallas grandes
            margin: '10px auto', // Centrar contenido en pantallas grandes y medianas
            width: '100%', // Asegurar que ocupe el 100% del ancho en pantallas pequeñas
          }}
        >
          <Routes>
            <Route path="/" element={<EscuelasList />} />
            <Route path="/centro/:codigoSACE" element={<SchoolDetail />} />
          </Routes>
        </Layout.Content>
        <AppFooter />
      </Layout>
    </Router>
  );
}

export default App;
