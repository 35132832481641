import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Centro {
    CodigoSACE: any;
    DEPARTAMENTO: any;
    MUNICIPIO: any;
    ALDEA: any;
    CentroEducativo: any;
    Foto_CENTRO: any;
    Logo_CENTRO: any;
	Contacto_Director: any;
	Contacto_Docente_Enlace: any;
	Contacto_Transparencia: any;
	Correo_Institucional: any;
	Distrito: any;
	Nombre_Director: any;
	Nombre_Docente_Enlace: any;
	Nombre_Transparencia: any;
	Red_Educativa: any;
}

interface Docente {
    NumeroDocumento: any;
    Nombre_Docente: any;
    Grado: any;
    Seccion: any;
    Matricula_Niñas: any;
    Matricula_Varones: any;
    Matricula_Total: any;
    Jornada_Matutina: any;
    Jornada_Vespertina: any;
    Jornada_Nocturna:any;
    Acuerdo_Permanente: any;
    Acuerdo_Asignado: any;
    Acuerdo_Contrato: any;
    Acuerdo_Interino:any;
    Horas_Clase_Acuerdo:any;
    Observaciones:any;
    Codigo_SACE: any;
}

interface PagosEspeciales {
    Nombre_Empleado: any;
    Cargo_Nominal: any;
    Cargo_Funcional: any;
    Jornada_Mixta: any;
    Jornada_Diurna: any;
    Jornada_Nocturna: any;
    Acuerdo_Permanente: any;
    Acuerdo_Contrato: any;
    Observaciones: any;
    Fuentes_Financiamiento: any;
    Codigo_SACE: any;
}

interface PersonalDeServicioCivil {
    ID: any;
    Nombre_Empleado: any;
    Cargo_Nominal: any;
    Cargo_Funcional: any;
    Jornada_Mixta: any;
    Jornada_Diurna: any;
    Jornada_Nocturna: any;
    Acuerdo_Permanente: any;
    Acuerdo_Contrato: any;
    Observaciones: any;
    Codigo_SACE: any;
}

interface InformesFinancieros {
    Codigo_SACE: any;
    Imagen_Liquidacion_Matricula_Gratis: any;
    Imagen_Liquidacion_Fondos_Propios: any;
}

interface Inventario {
    Articulo_Descripcion: any;
    Cantidad: any;
    Condicion: any;
    Uso: any;
    Origen_Fondo: any;
    Fecha_Adquisicion: any;
    Codigo_SACE: any;
}

interface ListadosDeCentrosState {
    centros: Centro[];
    docentes: Docente[];
    pagosEspeciales: PagosEspeciales[];
    personalDeServicioCivil: PersonalDeServicioCivil[];
    informesFinancieros: InformesFinancieros[];
    inventario: Inventario[];
}

const initialState: ListadosDeCentrosState = {
    centros: [],
    docentes: [],
    pagosEspeciales: [],
    personalDeServicioCivil: [],
    informesFinancieros: [],
    inventario: []
};

const listadosDeCentrosSlice = createSlice({
    name: 'listadosDeCentros',
    initialState,
    reducers: {
        setCentros(state, action: PayloadAction<Centro[]>) {
            state.centros = action.payload;
        },
        setDocentes(state, action: PayloadAction<Docente[]>) {
            state.docentes = action.payload;
        },
        setPagosEspeciales(state, action: PayloadAction<PagosEspeciales[]>) {
            state.pagosEspeciales = action.payload;
        },
        setPersonalDeServicioCivil(state, action: PayloadAction<PersonalDeServicioCivil[]>) {
            state.personalDeServicioCivil = action.payload;
        },
        setInformesFinancieros(state, action: PayloadAction<InformesFinancieros[]>) {
            state.informesFinancieros = action.payload;
        },
        setInventario(state, action: PayloadAction<Inventario[]>) {
            state.inventario = action.payload;
        }
    },
});

export const { setCentros } = listadosDeCentrosSlice.actions;
export const { setDocentes } = listadosDeCentrosSlice.actions;
export const { setPagosEspeciales } = listadosDeCentrosSlice.actions;
export const { setPersonalDeServicioCivil } = listadosDeCentrosSlice.actions;
export const { setInformesFinancieros } = listadosDeCentrosSlice.actions;
export const { setInventario } = listadosDeCentrosSlice.actions;

export default listadosDeCentrosSlice.reducer;