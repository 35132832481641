import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Typography, Tabs, Empty, Collapse, Descriptions, Badge, Row, Col } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import DocentesList from './DocentesList';
import AdministrativosList from './AdministrativosList';
import PagosEspecialesList from './PagosEspecialesList';
import { RootState } from '../store/store';
import InformesFinancieros from './InformesFinancieros';
import MatriculaGratis from './MatriculaGratis';
import Inventarios from './Inventarios';
import AlimentacionEscolar from './AlimentacionEscolar';
import Becas from './Becas';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const SchoolDetail: React.FC = () => {
  const { codigoSACE } = useParams<{ codigoSACE: string }>();
  
  // Selector actualizado para obtener los datos del centro
  const centro = useSelector((state: RootState) =>
    state.listadosDeCentros.centros.find(c => c.CodigoSACE === codigoSACE)
  );

  const docentes = useSelector((state: RootState) =>
    state.listadosDeCentros.docentes.filter(doc => doc.Codigo_SACE === codigoSACE)
  );
  const administrativos = useSelector((state: RootState) =>
    state.listadosDeCentros.personalDeServicioCivil.filter(admin => admin.Codigo_SACE === codigoSACE)
  );
  const pagosEspeciales = useSelector((state: RootState) =>
    state.listadosDeCentros.pagosEspeciales.filter(pago => pago.Codigo_SACE === codigoSACE)
  );
  const informesFinancieros = useSelector((state: RootState) =>
    state.listadosDeCentros.informesFinancieros.filter(info => info.Codigo_SACE === codigoSACE)
  );
  const inventario = useSelector((state: RootState) =>
    state.listadosDeCentros.inventario.filter(inv => inv.Codigo_SACE === codigoSACE)
  );

  // Verificar si hay pestañas disponibles para cada categoría
  const tieneRecursosHumanos = docentes.length > 0 || administrativos.length > 0 || pagosEspeciales.length > 0;
  const tieneRecursosEconomicos = informesFinancieros.length > 0 || inventario.length > 0;

  return (
    <div style={{ padding: '20px', backgroundColor: '#f0f2f5', minHeight: '100vh' }}>
      {centro ? (
        <Card
          style={{
            maxWidth: '100%',
            margin: '0 auto',
            padding: '20px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
          }}
          bodyStyle={{ padding: '20px' }}
        >
          {/* Título del Centro */}
          <Title
            level={2}
            style={{
              color: '#fff',
              backgroundColor: '#E30613',
              textAlign: 'center',
              marginBottom: '20px',
              padding: '15px',
              borderRadius: '10px',
              fontWeight: 'bold',
              letterSpacing: '1px',
            }}
          >
            {centro.CentroEducativo}
          </Title>

          {/* Panel de Ubicación en formato de columnas */}
          <Collapse accordion={false} bordered={false} defaultActiveKey={['1', '2']}>
            <Panel header={<strong>📍 Ubicación del Centro</strong>} key="1">
              <Descriptions
                bordered
                size="small"
                column={{
                  xs: 1,
                  sm: 1,
                  md: 2,
                  lg: 2,
                }}
              >
                <Descriptions.Item label="Código SACE">
                  <Badge color="blue" text={centro.CodigoSACE} />
                </Descriptions.Item>
                <Descriptions.Item label="Aldea">
                  <Badge color="green" text={centro.ALDEA} />
                </Descriptions.Item>
                <Descriptions.Item label="Municipio">
                  <Badge color="purple" text={centro.MUNICIPIO} />
                </Descriptions.Item>
                <Descriptions.Item label="Departamento">
                  <Badge color="orange" text={centro.DEPARTAMENTO} />
                </Descriptions.Item>
                <Descriptions.Item label="Red Educativa">
                  <Badge color="blue" text={centro.Red_Educativa || 'No disponible'} />
                </Descriptions.Item>
                <Descriptions.Item label="Distrito">
                  <Badge color="blue" text={centro.Distrito || 'No disponible'} />
                </Descriptions.Item>
              </Descriptions>
            </Panel>

            {/* Panel de Recursos Administrativos con Contactos */}
            <Panel header={<strong>👥 Recursos Administrativos y Contactos</strong>} key="2">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={8}>
                  <Card bordered={false}>
                    <Badge color="cyan" text="Director" />
                    <p><strong>{centro.Nombre_Director || 'No disponible'}</strong></p>
                    <p><PhoneOutlined /> {centro.Contacto_Director || 'No disponible'}</p>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Card bordered={false}>
                    <Badge color="cyan" text="Docente Enlace" />
                    <p><strong>{centro.Nombre_Docente_Enlace || 'No disponible'}</strong></p>
                    <p><PhoneOutlined /> {centro.Contacto_Docente_Enlace || 'No disponible'}</p>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Card bordered={false}>
                    <Badge color="gold" text="Responsable de Transparencia" />
                    <p><strong>{centro.Nombre_Transparencia || 'No disponible'}</strong></p>
                    <p><PhoneOutlined /> {centro.Contacto_Transparencia || 'No disponible'}</p>
                  </Card>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          {/* Recursos Humanos Docentes */}
          {tieneRecursosHumanos && (
            <>
              <Title
                level={3}
                style={{
                  marginTop: '40px',
                  textAlign: 'center',
                  color: '#E30613',
                  fontWeight: 'bold',
                  paddingBottom: '10px',
                  letterSpacing: '0.5px',
                }}
              >
                👨‍🏫 Recursos Humanos Docentes
              </Title>

              <Tabs defaultActiveKey="1" centered type="card" size="large">
                {docentes.length > 0 && (
                  <TabPane tab="Docentes" key="1">
                    <DocentesList />
                  </TabPane>
                )}
                {administrativos.length > 0 && (
                  <TabPane tab="Administrativos" key="2">
                    <AdministrativosList />
                  </TabPane>
                )}
                {pagosEspeciales.length > 0 && (
                  <TabPane tab="Pagos Especiales" key="3">
                    <PagosEspecialesList />
                  </TabPane>
                )}
              </Tabs>
            </>
          )}

          {/* Recursos Económicos */}
          {tieneRecursosEconomicos && (
            <>
              <Title
                level={3}
                style={{
                  marginTop: '40px',
                  textAlign: 'center',
                  color: '#E30613',
                  fontWeight: 'bold',
                  paddingBottom: '10px',
                  letterSpacing: '0.5px',
                }}
              >
                💼 Recursos Económicos
              </Title>

              <Tabs defaultActiveKey="1" centered type="card" size="large">
                {informesFinancieros.length > 0 && (
                  <TabPane tab="Informes Financieros" key="1">
                    <InformesFinancieros />
                  </TabPane>
                )}
                {inventario.length > 0 && (
                  <TabPane tab="Inventarios" key="3">
                    <Inventarios />
                  </TabPane>
                )}
              </Tabs>
            </>
          )}
        </Card>
      ) : (
        <Empty description="Centro no encontrado" style={{ marginTop: '20vh', textAlign: 'center' }} />
      )}
    </div>
  );
};

export default SchoolDetail;
