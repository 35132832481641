import 'antd/dist/reset.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/store';
import { 
  setCentros, 
  setDocentes, 
  setPagosEspeciales,
  setPersonalDeServicioCivil,
  setInformesFinancieros,
  setInventario
} from './store/slices/listadosDeCentrosSlice';
import { 
  data_ListadosDeCentros, 
  data_ListadoDeDocentes, 
  data_PagosEspeciales,
  data_PersonalDeServicioCivil,
  data_InformesFinancieros,
  data_Inventario
} from './data';

// Cargar los datos de centros y docentes en el store
store.dispatch(setCentros(data_ListadosDeCentros));
store.dispatch(setDocentes(data_ListadoDeDocentes));
store.dispatch(setPagosEspeciales(data_PagosEspeciales));
store.dispatch(setPersonalDeServicioCivil(data_PersonalDeServicioCivil));
store.dispatch(setInformesFinancieros(data_InformesFinancieros));
store.dispatch(setInventario(data_Inventario));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
